import { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setHasPassword, setUiFlag } from "../../redux/features/authSlice"
import Draggable from "react-draggable"

import '../components.css'

function PasswordForm() {

  const [passphrase, setPassphrase] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [correct, setCorrect] = useState(false)
  const userName = useSelector((state) => state.auth.username)
  const dispatch = useDispatch()

  var timer = useRef(null)

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!passphrase) { return }
    setSubmitted(true)
    clearTimeout(timer.current); 
    setCorrect(true)
    try {
      const response = await fetch("/validatePassword?password=" + passphrase + "&username=" + userName, {
      }).then(response => response.json())
      //console.log(response)
      if (response?.valid) {
        document.cookie = `token=${response["token"]}`
        dispatch(setHasPassword())
        dispatch(setUiFlag(response.uiFlag))
        return
      } 
    } catch (err) {
      console.log(err)
    }
    setCorrect(false)
    // reset after timer
    timer.current = setTimeout(() => {
      setSubmitted(false)
    }, 3000)
  }

  return (
    <Draggable positionOffset={{ x: '-50%', y: '-50%' }}
      bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
      handle=".tabHeadingBar">
      <div className="no-cursor tabDefaults passwordFormTab windows95Tab">
        <div className="tabHeadingBar">
          <div className="tabHeading">Saiko</div>
        </div> 
        <div className="passwordFormText">
          <form onSubmit={handleSubmit}>
            <div className="centerText"><label>Password:</label></div>
            <div className="centerText"><input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
            /></div>
            <div className="centerText"><input className="submit" type="submit" value="Submit" autoCapitalize="off"></input></div>
            <div style={{color: "red", "fontSize": "30px", marginLeft: "10px", marginTop: "5px", height: "1em", width: "200px"}} className="passwordFormResponseText">{(!correct && submitted) ? "Incorrect!" : ""}</div>
            </form>
        </div>
      </div>
    </Draggable>);
}

export default PasswordForm;