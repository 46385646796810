import React from "react"
import './components.css'
import { useSelector } from "react-redux";
import DesktopIcons from "./DesktopIcons";
import { Review, Scheduler } from './Tabs/AllTabs'
import TaskBar from './TaskBar.jsx'

function LandingPage2() {
    const reviewed = useSelector((state => state.auth.reviewed))
    return (<>
        <div className="container">
            <DesktopIcons/>
            {!reviewed ? <Review/> : <></> }
            {reviewed ? <Scheduler/> : <></> }
        </div>
        <TaskBar/>
    </>
    );
}

export default LandingPage2;