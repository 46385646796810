import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import Draggable from "react-draggable"
import '../components.css'
import { setReviewed } from "../../redux/features/authSlice"
import { Link } from "react-router-dom"

function Review() {
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const username = searchParams.get("handle")
    const passcode = searchParams.get("code")
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(false)

    useEffect(() => {
        async function verifyCompletion() {
            try {
                const response = await fetch(`/verifySurvey2?name=${username}&code=${passcode}`).then(response => response.json())
                //console.log("verifySurvey2 response", response)
                if (response?.valid) setIsValid(true)
            }
            catch (err) {
                console.log('error fetching', err)
            }
        }
        verifyCompletion()
    }, [dispatch, setIsValid, passcode, username])

    return (
        <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab"} >
                <div className="tabHeadingBar"><div className="tabHeading">Review</div></div>
                <div className="tabContents">
                    {!isValid ?
                        <div style={{ margin: "30px" }}>Loading...</div> :
                        <>
                            <div style={{ margin: "20px" }}>
                                <div className="tab-text" style={{ margin: "30px" }}>You may view your personality survey results if you like.</div>
                                <div className="tab-text" style={{ margin: "30px" }}>This is your only chance to view and save them. We do not have any other way to get them to you.</div>
                                <div className="largeTabText centerText">
                                    <Link to={'/results'} target="_blank" rel="noopener noreferrer" >VIEW RESULTS</Link>
                                </div>
                                <div style={{ margin: "30px" }}>Please continue to schedule a slot for SaikoCTF.</div>
                                <div className="centerText"><button className="tabDefaultButton" onClick={() => dispatch(setReviewed())}>continue</button></div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Draggable>);
}

export default Review;