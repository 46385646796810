import LandingPage from './components/LandingPage.jsx'
import LandingPage2 from './components/LandingPage2.jsx'
import SurveyResults from './components/Tabs/SurveyResults.jsx'
import SurveyUrls from './components/SurveyUrls.jsx'
import Survey1 from './components/Survey1.jsx'
import DeclinePage from './components/DeclinePage.jsx'
import { store } from './redux/store.js'
import { Provider } from 'react-redux'
import './App.css'
import React from "react";
import {
  HashRouter as Router,
  //BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import InfoSite from './info-site/InfoSite.jsx'

function App() {
  return  <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/ecsc-info" element={<InfoSite link="saikoctf.org/#/ecsc"/>} />
        <Route path="/eko-info" element={<InfoSite link="saikoctf.org/#/eko"/>} />
        <Route path="ecsc" element={<LandingPage uiFlagForced="ecsc"/>} />
        <Route path="survey1" element={<Survey1 />} />
        <Route path="phase2" element={<LandingPage2 />} />
        <Route path="results" element={<SurveyResults />} />
        <Route path="urls" element={<SurveyUrls />} />
        <Route path="decline" element={<DeclinePage />} />
      </Routes>
  </Router>
  </Provider>
}

export default App;
