import './components.css'

function DesktopIcons() {

    function DesktopIcon({ desktopIconImg, text }) {
        return (
        <div className='noSelect desktopIcons'>
            <div className={desktopIconImg + " desktopIconsImage"}/>
            <div className='desktopIconTextStyling'>{text}</div>
        </div>
        )
    }

    return (
            <div className="icons">
                <DesktopIcon desktopIconImg={"myComputer"} text={"My Computer"} />
                <DesktopIcon desktopIconImg={"networkNeighborhood"} text={"Network Neighborhood"}/>
                <DesktopIcon desktopIconImg={"recycle"} text={"Recycle Bin"}/>
                <DesktopIcon desktopIconImg={"minesweeper"} text={"Minesweeper"}/>
                <DesktopIcon desktopIconImg={"interspaceNavigator"} text={"Interweb Navigator"}/>
            </div>
    );
}

export default DesktopIcons;