import { useEffect } from "react";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUsername, setPasscode, setPickedName, setRedcapSurveyUrl, setToken } from "../../redux/features/authSlice";
import Draggable from "react-draggable";
import '../components.css'

function NamePicker() {
    const uiFlag = useSelector((state) => state.auth.uiFlag)
    const [claimFailure, setClaimFailure] = useState(false)
    const [potentialUsername, setPotentialUsername] = useState("")
    const dispatch = useDispatch()
    var timer = useRef(null)

    async function fetchUsername () {
        return fetch("/randomName").then(
            response => response.json()).then(res => setPotentialUsername(res.randomName))
    }

    // // set random username as permanent for auth (used for redirect)
    useEffect( () => {
        fetchUsername()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onRerollClick () {
        try {
            await fetchUsername()
            // const response = await fetch("/randomName", {
            // }).then(response => response.json())
            // dispatch(setUsername(response.randomName))
        } catch (err) {
            console.log(err)
        }
    }

    async function onContinueClick(event) {
        event.preventDefault();
        clearTimeout(timer.current); 
        try {
            const response = await fetch("/claimName?" + new URLSearchParams({
                name: potentialUsername,
                uiFlag: uiFlag,
            }).toString(), {
            }).then(response => response.json())
            if (!response.passcode) {
                setClaimFailure(true)
                // reset after timer
                timer.current = setTimeout(() => {
                    setClaimFailure(false)
                }, 3000)

            } else {
                dispatch(setPasscode(response.passcode))
                dispatch(setRedcapSurveyUrl(response.url))
                dispatch(setToken(response.token))
                dispatch(setUsername(potentialUsername))
                dispatch(setPickedName())
            }
        } catch (err) {
            console.log(err)
        }

    }



    return (
        <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className="no-cursor tabDefaults namePickerTab windows95Tab">
                <div className="tabHeadingBar"><div className="tabHeading">Please Choose A Handle</div></div>
                <div className="margins">
                    <div className="tabText">Please choose a handle. Your handle will be the only means we have to identify you.<br/><br/>Your current handle:</div>
                    <div className="blue largeTabText wrapped">{potentialUsername}</div>
                </div>
                <div style={{marginTop: "30px"}} className="centerText">
                    <button className="tabDefaultButton" onClick={onRerollClick}>Reroll</button>
                    <button className="tabDefaultButton" onClick={onContinueClick}>Continue</button>
                </div>
                {/* <div className="tabText">If you're happy with this handle, continue:</div> */}
                <div className="centerText">
                    <div style={{display: "inline-block", color: "red", "fontSize": "30px", marginTop: "5px", height: "1em"}} className="passwordFormResponseText">{claimFailure ? "Error. Please choose another name." : ""}</div>
                </div>
            </div>
        </Draggable>
    );
}

export default NamePicker;