import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hasPassword: false,
  hasTimeAck: false,
  hasConsent: false,
  pickedName: false,
  hasConsent2: false,
  hasProjectInfoAck: false,
  survey1: false,
  survey2: false,
  reviewed: false,
  username: "",
  passcode: "",
  uiFlag: "",
  redcapSurveyUrl: "",
  token: ""
}


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      return {
        ...state,
        username: action.payload
      } 
    },
    setPasscode: (state, action) => {
      return {
        ...state,
        passcode: action.payload
      } 
    },
    setRedcapSurveyUrl: (state, action) => {
      return {
        ...state,
        redcapSurveyUrl: action.payload
      } 
    },
    setUiFlag: (state, action) => {
      return {
        ...state,
        uiFlag: action.payload
      } 
    },
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload
      } 
    },
    setHasPassword: (state) => {
      return {
        ...state,
        hasPassword: true
      } 
    },
    setHasConsent: (state) => {
      return {
        ...state,
        hasConsent: true
      } 
    },
    setPickedName: (state) => {
      return {
        ...state,
        pickedName: true
      } 
    },
    setTimeAck: (state) => {
      return {
        ...state,
        hasTimeAck: true
      }
    },
    setHasConsent2: (state) => {
      return {
        ...state,
        hasConsent2: true
      } 
    },
    setSurvey1Complete: (state) => {
      return {
        ...state,
        survey1: true
      } 
    },
    setSurvey2Complete: (state) => {
      return {
        ...state,
        survey2: true
      } 
    },
    setReviewed: (state) => {
      return {
        ...state,
        reviewed: true
      } 
    },
    setHasProjectInfoAck: (state) => {
      return {
        ...state,
        hasProjectInfoAck: true
      } 
    }
  },
})

export const { setHasPassword, setHasConsent, setTimeAck, setHasConsent2, setUsername, setPasscode, setUiFlag, setPickedName, setSurvey1Complete, setSurvey2Complete, setReviewed, setRedcapSurveyUrl, setHasProjectInfoAck, setToken } = authSlice.actions

export default authSlice.reducer