import React from 'react';
import { useEffect, useState } from 'react';
import './components.css'





function TaskBar() {
  const [time, setTime] = useState(getCurrentTime())

  function getCurrentTime(){
    const now = new Date()
    return now.getHours() + ' : ' +
        now.getMinutes().toString().padStart(2, "0") + ' : ' +
        now.getSeconds().toString().padStart(2, "0")
    
  }

  function TaskBarButton(props) {
    return (<button onClick={() => props.resetArrayWithNdx(props.index)}
      className={`taskBarButton ${props.extraClass} ` + (props.selected ? "windows95TabInverse" : "windows95Tab")}
      style={{ width: (props.width) ? props.width : "" }} >
      <div className={`taskBarButtonContent ` + (props.hasIcon ? "taskBarButtonIcon" : "")}>
        {props.name}
      </div>
    </button>);
  }

  const buttons = [{ name: "Start", hasIcon: true, extraClass: "start taskBarIcon", width: 150 },
  { name: "Welcome", hasIcon: false, extraClass: "",  },
  { name: "Saiko", hasIcon: false, extraClass: "", },
  ]

  const [selectedArray, setSelectedArray] = useState(Array(buttons.length).fill(false))

  function resetArrayWithNdx(ndx) {
    setSelectedArray(Array(buttons.length).fill(false))
    setSelectedArray(selectedArray.map((x, i) => i === ndx ? true : false))
  }

  useEffect(() => {
    resetArrayWithNdx(2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setInterval(() => {
      setTime(getCurrentTime())
    }, 1000)
  }, [])

  return (<div className='noSelect taskBar'>
    {buttons.map((props, i) =>
      <TaskBarButton key={props.name} selected={selectedArray[i]}
        index={i}
        resetArrayWithNdx={resetArrayWithNdx}
        name={props.name}
        hasIcon={props.hasIcon}
        extraClass={props.extraClass}
        width={props.width} />)}
    <div className='clockButton clockBorderStyling'><div className='clockStyle'>{time}</div></div>
  </div>);
}

export default TaskBar;