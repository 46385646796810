import React from "react"
import './components.css'
import DesktopIcons from "./DesktopIcons.jsx";
import Draggable from "react-draggable";
import TaskBar from './TaskBar.jsx'
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Countdown from 'react-countdown';
import pwnCollege1 from '../resources/pwn.college1.png'

const defaultPollIntervalMs = 60 * 1000
const timerBufferMs = 1 * 1000

function Survey1() {
    const location = useLocation()
    //console.log("location state", location, location?.state)
    const username = location?.state?.username
    const passcode = location?.state?.passcode
    const eventKey = location?.state?.eventKey
    const redcapSurveyUrl = location?.state?.rcu
    const pwnCollegeSurveyUrl = 'https://pwn.college/login?next=/skill-screener~e6c3a6ef?'
    const [curStatus, setCurStatus] = useState({});

    useEffect(() => { 
       async function verifySkillScreener () {
        fetch(`/verifySkillScreener?name=${username}&code=${passcode}&eventKey=${eventKey}`)
            .then(response => response.json())
            .then(status => {
                //console.log("verifySkillScreener result", status)
                setCurStatus(status)
                if (!status?.valid && status?.remainingMs !== 0) {
                  const t = Math.min((status?.remainingMs || 0) + timerBufferMs, defaultPollIntervalMs)
                   // console.log("verifySkillScreener setTimeout1", t)
                  setTimeout(verifySkillScreener, t)
                }
            })
            .catch (err => {
                console.error("verifySkillScreener api failed", err)
                setTimeout(verifySkillScreener, defaultPollIntervalMs)
            })
      } 
      verifySkillScreener()
    }, [eventKey, passcode, redcapSurveyUrl, username])    

    function countdownRenderer ({hours, minutes, seconds, completed}) {
      // if (completed) {
      //       return <></> 
      // } else {
          // Render a countdown
          return <>
            <div style={{marginTop: "30px"}}>After {`${curStatus?.allottedMinutes}`} minutes return to this page to continue registration. Do not close this tab.</div>
            <div className="wrapped" style={{marginTop: "30px"}}>Your handle: <strong>{username}</strong></div>
            <div className="wrapped" style={{marginTop: "30px"}}>Your password: <strong>{passcode}</strong></div>
            <div style={{marginTop: "30px"}}><span>Time remaining: <strong>{String(minutes).padStart(2,'0')}:{String(seconds).padStart(2, '0')}</strong></span></div>
            <div style={{marginTop: "30px"}} className="largeTabText centerText"><a href={pwnCollegeSurveyUrl} target="_blank" rel="noreferrer">GO TO SKILL SCREENER</a></div>
          </>
        // }
      };

    function renderBody () {
      if (curStatus?.valid) {
        if (curStatus?.skipped) {
          return <div>
                    Thank you for registering for SaikoCTF!
                    <br/><br/>
                    Please complete the Survey below to continue.
                    <br/><br/>
                    <div className="centerText"><button className="tabDefaultButton" onClick={() => window.location.href = redcapSurveyUrl}>TAKE SURVEY</button></div>
            </div>
        } else {
          return <div>
                    Congratulations, you qualified for SaikoCTF!
                    <br/><br/>
                    Please complete the Survey below to continue.
                    <br/><br/>
                    <div className="centerText"><button className="tabDefaultButton" onClick={() => window.location.href = redcapSurveyUrl}>TAKE SURVEY</button></div>
            </div>
        }
        
      }


      if (curStatus?.valid) {
        return <div>
                  Congratulations, you qualified for SaikoCTF!
                  <br/><br/>
                  Please complete the Survey below to continue.
                  <br/><br/>
                  <div className="centerText"><button className="tabDefaultButton" onClick={() => window.location.href = redcapSurveyUrl}>TAKE SURVEY</button></div>
          </div>
        
      }
      
      if (!('remainingMs' in curStatus) || !('deadline' in curStatus)) {
        return <div>Loading...</div>
      } 
      if (curStatus?.remainingMs === 0 && !curStatus?.valid) {
        return <div>Thank you for your time, but you did not pass the skill screener in the time allotted. Goodbye.</div>
      }


      return <>
        <div>
          To ensure that you enjoy our SaikoCTF challenges, we ask you to take a skill screener test.
          <br/><br/>
          To take the test, please click on the link below. You will need your handle and password to log into the skill screener.
          <br/><br/>
          Try your best at the skill screener, but do not spend more than {`${curStatus?.allottedMinutes}`} minutes. 
          To start each challenge, click on 
          <strong>"VSCode Workspace"</strong> or <strong>"GUI Desktop Workspace"</strong> as shown below.
        </div>
        <img style={{marginTop: "20px", marginLeft: "auto", marginRight: "auto", display: "block"}} alt="pwncollege1"  src={pwnCollege1}></img>
        <Countdown date={Date.parse(curStatus?.deadline) + timerBufferMs} renderer={countdownRenderer}/>
      </>

    }
  

    return (<>
        <div className="container">
            <DesktopIcons/>
            <Draggable
            positionOffset={{ x: '-50%', y: '-50%' }}
            bounds={{ left: -850, right: 850, top: -450, bottom: 500 }}
            handle=".tabHeadingBar">
            <div className={"cursor tabDefaults windows95Tab skillscreenertab"} >
                <div className="tabHeadingBar"><div className="tabHeading">Skill Screener</div></div>
                <div className="tabContents">
                  <div style={{margin: "20px"}}>
                    {renderBody()}
                  </div>
                {/* <div className="centerText"><button className="tabDefaultButton" onClick={() => dispatch(setSurvey1Complete())}>continue (temp)</button></div> */}
                </div>
            </div>
            </Draggable>
            
        </div>
        <TaskBar />
    </>
    );
}

export default Survey1;