// ReactiveText.jsx
import React, { useEffect, useRef } from 'react';
import './InfoSite.css'; // Adjust the path as necessary

const ReactiveText = ({text, extraclasses, child}) => {
    const textRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (textRef.current) {
                const { clientX: mouseX, clientY: mouseY } = event;
                const { left, top, width, height } = textRef.current.getBoundingClientRect();
                const centerX = left + width / 2;
                const centerY = top + height / 2;
                const deltaX = mouseX - centerX;
                const deltaY = mouseY - centerY;

                // Calculate tilt angles
                const maxTilt = 20; // Maximum tilt angle in degrees
                const angleX = Math.max(-maxTilt, Math.min(maxTilt, (deltaY / height) * maxTilt));
                const angleY = Math.max(-maxTilt, Math.min(maxTilt, (deltaX / width) * -maxTilt));

                // Apply tilt effect
                textRef.current.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg)`;
            }
        };
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className={`${extraclasses} reactive`} ref={textRef}>
           {text}
           {child}
        </div>
    );
};

export default ReactiveText;