import { useState, useEffect } from "react";
import '../components.css'
import background from '../../resources/about/background.pdf'
import scoring from '../../resources/about/scoring.pdf'
import descriptions from '../../resources/about/descriptions.pdf'
import fantasyCharacters from '../../resources/about/FantasyCharacters.pdf'

/* Pick a theme of your choice */
// import original from 'react95/dist/themes/original';
// import { styleReset } from 'react95';
// import { createGlobalStyle, ThemeProvider } from 'styled-components';

// /* Original Windows95 font (optional) */
// import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
// import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';


// //import logo from '../../resources/uniqlo.png'

// const GlobalStyles = createGlobalStyle`
//   ${styleReset}
//   @font-face {
//     font-family: 'ms_sans_serif';
//     src: url('${ms_sans_serif}') format('woff2');
//     font-weight: 400;
//     font-style: normal
//   }
//   @font-face {
//     font-family: 'ms_sans_serif';
//     src: url('${ms_sans_serif_bold}') format('woff2');
//     font-weight: bold;
//     font-style: normal
//   }
//   body, input, select, textarea {
//     font-family: 'ms_sans_serif';
//   }
// `;

// function resultToString(res) {
//   if (!res) return "0%" 
//   return String(Math.floor((Number(res) / 5)*100)) + "%"
// }  

const fieldDescriptions = [
    { field: 'bfi2_open_mindedness', name: 'Openness (Curiosity)', description: 'Being engaged in arts, science, and the humanities. Thinking about the world. Coming up with creative ideas and solutions. Being engaged in learning more.'},
    { field: 'bfi2_conscientiousness', name: 'Conscientiousness (Work Ethic)', description: 'Being fully prepared and organized. Being precise, efficient, by setting meaningful goals. Doing more than expected of you. Working hard. Being adaptable along these lines to accomplish tasks on time and at high quality.	'},
    { field: 'bfi2_extraversion', name: 'Extraversion (Communication Style)', description: 'Being skilled in social situations. Being engaged in group activities. Feeling at ease with fellow colleagues. Communicating a lot with people.	'},
    { field: 'bfi2_agreeableness', name: 'Agreeableness (Teamwork)', description: 'Being generous and kind to people. Enjoying helping people. Being honest and respectful of others’ feelings. Being interested in charity and causes.	'},
    { field: 'bfi2_emotional_stability', name: 'Emotional Stability (Stress Tolerance)', description: 'Being able to deal with stress. Being able to monitor your emotions. Staying cool, calm, and collected. Being able to handle criticism.	'},
]

function resultToDisplay(res) {
    if (!res) return '-'
    return Number(res).toFixed(1)
}

function SurveyResults() {
    const [surveyResults, setSurveyResults] = useState({});
    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const username = searchParams.get("handle")
    const code = searchParams.get("code")

    useEffect(() => {
        async function fetchSurveyResults() {
            try {
                const response = await fetch(`/surveyResults?name=${username}&code=${code}`).then(response => response.json())
                if (response && response?.survey_results) {
                    //console.log('survey results', response.survey_results)
                    setSurveyResults(response)
                }
            }
            catch (err) {
                console.log('error fetching', err)
            }
        }
        fetchSurveyResults()
    }, [setSurveyResults, code, username])

    //cursor tabDefaults windows95Tab reviewtab
    return (
        <div className={"scroll visible-print surveyResults"}>
            {/* <GlobalStyles /> */}
            {/* <ThemeProvider theme={original}> */}
                <div style={{ height: "50px" }} className="tabHeadingBar"><div className="tabHeading">Saiko Soft Skills Assessment Results</div></div>
                {Object.keys(surveyResults).length === 0 ?
                    <div style={{ margin: "30px", fontSize: "30px" }}>Loading data...</div> :
                    <>
                        {/* <img src={logo} style={{height: "50px", width: "50px"}} alt="logo"></img> */}
                        <div>
                            <div className="overflowX" style={{ margin: "20px", fontSize: "30px" }}>
                                <div style={{ margin: "30px" }}>Handle: <strong>{username}</strong></div>
                                <div style={{ margin: "30px" }}>Passcode: <strong>{code}</strong></div>

                                <div style={{ margin: "30px" }}>
                                    During these screener tasks, you completed a series of questions that allow us to give you feedback on your soft skills, which include attributes like teamwork, work ethic, and stress tolerance.
                                    <br/><br/>
                                    Below are these results, on a scale from 1 to 10 (the idea of not having any of these skills precludes a zero-point). Your results have been ingested with available population statistics, so that they have scientific merit. The higher your score, the more proficient you are on each of the dimensions relative to the general population. 
                                </div>

                                <table style={{ margin: "50px", border:"2px solid", borderCollapse:"collapse" }}>
                                    <tbody>
                                        {fieldDescriptions.map((val) =>
                                            (<tr style={{}} key={val.field}>
                                                <td style={{padding: "10px", margin: "5px", width: "90%", border:"2px solid" }} key={val.name}>
                                                    <strong>{val.name}</strong>: {val.description}
                                                </td>
                                                <td style={{border:"2px solid", textAlign: "center", verticalAlign: "middle"}} key={val.description} >
                                                    {resultToDisplay(surveyResults.survey_results[val.field])}
                                                    {/* <ProgressBar style={{ width: "150px" }} value={resultToPercent(surveyResults[key])} /> */}
                                                </td>
                                            </tr>)
                                        )}
                                    </tbody>
                                </table>

                                <div style={{ margin: "30px" }}>
                                    Your Myers-Briggs score is <strong>{`${surveyResults?.myers_briggs}`}</strong>. <a href={fantasyCharacters}>See what fantasy characters are like you.</a>
                                    <br/><br/>
                                    <a href={background}>Learn more about the theory</a> of these attributes and what science teaches us about them. 
                                    <br/><br/>
                                    <a href={scoring}>Learn more about how the scores are derived</a> and where your score stands with respect to the general population. 
                                    <br/><br/>
                                    <a href={descriptions}>Deeper dive into the meaning</a> of each attribute. 
                                </div>


                            </div>
                        </div>
                    </>
                }
            {/* </ThemeProvider> */}
        </div>);
}

export default SurveyResults;