import React from "react"
import './components.css'
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const checkboxes = [
  'Too long', 
  'Not enough time right now',
  "Don't agree with terms", 
]

function DeclinePage() {
    const [comments, setComments] = useState("")
    const [phase, setPhase] = useState(0);
    const [checkedState, setCheckedState] = useState(new Array(checkboxes.length).fill(false));
    const location = useLocation()
    //console.log("location state", location?.state)
    const username = location?.state?.username || ""
    const passcode = location?.state?.passcode || ""

    async function handleSubmit (event)  {
      event.preventDefault();
      const texts = checkedState.reduce((filtered, val, index) => {
        if (val) filtered.push(checkboxes[index])
        return filtered
      }, [])
      if (!texts?.length && !comments) return // skip if no data

      //console.log("declineparticipation texts", texts)
      
      const params = {
        method: "POST",
        body: JSON.stringify({
          handle: username,
          passcode: passcode,
          rationale: {
            comments: comments,
            checkboxes: texts,
          }
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      }
   
      //console.log("declineparticipation params", params)

      fetch(`/declineparticipation`, params)
        .then(response => setPhase(phase+1))
        .catch(err => console.log('declineparticipation api failed', err))
    }

    function onCheckChange (position) {
      const updatedCheckedState = checkedState.map((item, index) =>
        index === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
    }

    return (
    <>
      {phase === 0 ?
        <div className="tabContents" style={{fontSize: "40px", margin: "50px"}}>
            <form onSubmit={handleSubmit}>
                <div>Please select reasons for declining:</div>
                {checkboxes.map((val,index) => (
                    <div key={val} style={{margin: "10px"}}>
                      <input className="declineCheckboxes"
                             type="checkbox"
                             checked={checkedState[index]}
                             onChange={()=> onCheckChange(index)}/>
                      {val}
                    </div>))}
                <div style={{marginTop: "50px"}}>Comments:</div>
                <textarea
                    style={{fontSize: "30px", marginTop: "10px"}}
                    rows="5" cols="50"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)} />
                <div style={{marginTop: "50px", marginLeft: "10px"}}>
                  <input className="submit" type="submit" value="Submit"></input>
                </div>
            </form>
        </div>
        : 
        <div style={{fontSize: "40px", margin: "50px"}}>
          Thank you for your valuable input. Have a nice day.
        </div>}
    </>);
}

export default DeclinePage;