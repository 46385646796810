import React from "react"
import './components.css'
import { useDispatch, useSelector } from "react-redux";
import DesktopIcons from "./DesktopIcons";
import {PasswordForm, NamePicker, Consent, TimeInfo, Scheduler, ProjectInfo } from './Tabs/AllTabs'
import { setHasPassword, setUiFlag } from "../redux/features/authSlice"
import TaskBar from './TaskBar.jsx'
import { useEffect } from "react";

function LandingPage({ uiFlagForced }) {
    const dispatch = useDispatch()
    useEffect(() => {
        if(uiFlagForced) {
            console.log("uiFlagForced", uiFlagForced)
            dispatch(setUiFlag(uiFlagForced))
            dispatch(setHasPassword())
        }
    })    
    const hasPassword = useSelector((state => state.auth.hasPassword))
    const hasTimeAck = useSelector((state) => state.auth.hasTimeAck)
    // const hasConsent = useSelector((state => state.auth.hasConsent))
    const pickedName = useSelector((state => state.auth.pickedName))
    const hasConsent2 = useSelector((state => state.auth.hasConsent2))
    const hasProjectInfoAck = useSelector((state => state.auth.hasProjectInfoAck))
    // const reviewed = useSelector((state => state.auth.reviewed))
    return (<>
        <div className="container">
            <DesktopIcons/>
            {!hasPassword ? <PasswordForm/> : <></> }
            {hasPassword && !hasTimeAck ?  <TimeInfo/> : <></> }
            {/* {hasTimeAck && !hasConsent ? <Recruitment/> : <></> } */}
            {hasTimeAck && !pickedName ? <NamePicker/> : <></> }
            {pickedName && !hasProjectInfoAck ? <ProjectInfo/> : <></> }
            {hasProjectInfoAck && !hasConsent2 ? <Consent/> : <></> }
            {/* {hasConsent2 && !reviewed ? <Review/> : <></> } */}
            {hasConsent2 ? <Scheduler/> : <></> }
        </div>
        <TaskBar />
    </>
    );
}

export default LandingPage;